<template>
  <div>
    <v-row>
      <v-col class="d-flex justify-end">
        <template>
          <div class="my-2">
            <v-btn class="btn-primary" fab small dark @click="OpenModalAdd()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </template>
      </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="listItem"
        :items-per-page="15"
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        loading-text="Loading... Please wait"
    >
      <!--Action -->
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="OpenModalEdit(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <!--Delete Modal-->
    <ModalAdd>
      <template @close="close">
        <v-card>
          <v-card-title>
            <span>ເພິ່ມຜະລິດຕະພັນ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ລະຫັດ*"
                        v-model="product.barcode"
                        :rules="barcodeRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.barcode }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື້ຜະລິດຕະພັນ*"
                        v-model="product.name"
                        :rules="nameRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.name }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="ລາຄາຕົ້ນທຶນ*"
                        v-model="product.purchase_price"
                        :rules="purchasePriceRules"
                        type="number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.purchase_price }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="ລາຄາຂາຍ*"
                        v-model="product.sale_price"
                        :rules="salePriceRules"
                        type="number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.sale_price }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalAdd()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="AddUser()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalAdd>

    <!--Edit Modal-->

    <ModalEdit>
      <template @close="close" v-slot="">
        <v-card>
          <v-card-title>
            <span>ແກ້ໄຂຜະລິດຕະພັນ</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="ລະຫັດ"
                        v-model="edit_item.barcode"
                        :rules="barcodeRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.barcode }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="ຊື່ຜະລິດຕະພັນ"
                        v-model="edit_item.name"
                        :rules="nameRules"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.name }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="ຕົ້ນທຶນ"
                        v-model="edit_item.purchase_price"
                        :rules="purchasePriceRules"
                        type="number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.purchase_price }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="ລາຄາຂາຍ"
                        v-model="edit_item.sale_price"
                        :rules="salePriceRules"
                        type="number"
                    ></v-text-field>
                    <p class="errors">
                      {{ server_errors.sale_price }}
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="CloseModalEdit()">
                Close
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="Update()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
          >Cancel
          </v-btn
          >
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm()"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </div>
</template>

<script>
//import Validate from "@/plugins/Validation/Branch/branch"
export default {
  data() {
    return {
      loading: false,
      TableLoading: false,
      listItem: [],
      selectedTruck: '',
      userID: '',
      title: '',
      description: '',
      product: {},
      edit_item: {},
      item_id: '',
      server_errors: {
        title: ''
      },
      headers: [{
        text: "ລະຫັດ", align: "start", sortable: false, value: "barcode",
      }, {
        text: "ຊື່ຜະລິຕະພັນ", align: "start", sortable: false, value: "name",
      },
        {
          text: "ຕົ້ນທຶນ", align: "start", sortable: false, value: "purchase_price",
        }, {
          text: "ລາຄາຂາຍ", align: "start", sortable: false, value: "sale_price",
        },
        {
          text: "Created", align: "start", sortable: false, value: "created_at",
        },
        {text: "Actions", value: "actions", sortable: false},],
      barcodeRules: [v => !!v || 'Barcode is required'],
      nameRules: [v => !!v || 'Name is required', v => (v && v.length >= 2) || 'Name must be less than 2 characters',],
      purchasePriceRules: [v => !!v || 'Purchase Price is required'],
      salePriceRules: [v => !!v || 'Sale Price is required'],
      toast: {
        value: true, color: 'success', msg: 'Success'
      },
      toast_error: {
        value: true, color: 'error', msg: 'Something when wrong!'
      }
    }
  },
  methods: {
    getData() {
      this.$admin.get('list-bottle-types').then(res => {
        setTimeout(() => {
          this.listItem = res.data.bottle_types;
          this.TableLoading = false;
        }, 100);
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },
    AddUser() {
      if (this.$refs.form.validate() == true) {
        this.Submit();
      }
    },
    Submit() {
      this.loading = true;
      this.$store.commit("Toast_State", this.toast);
      this.$admin.post('add-bottle-type', this.product)
          .then(res => {
            if (res.status == 201) {
              this.loading = false;
              this.CloseModalAdd();
              this.product = {};
              this.getData();
              this.reset();
              this.$store.commit("Toast_State", this.toast);
            }
          }).catch(error => {
        this.loading = false;
        this.$store.commit("Toast_State", this.toast_error);
        if (error.response.status == 422) {
          let obj = error.response.data.errors;
          for (let [key, user] of Object.entries(obj)) {
            this.server_errors[key] = user[0];
          }
        }
      });


    }, CloseModalAdd() {
      this.product = {};
      this.reset();
      this.$store.commit("modalAdd_State", false);
    }, OpenModalEdit(item) {
      this.edit_item = item;
      this.$store.commit("modalEdit_State", true);
    },

    Update() {
      if (this.$refs.form.validate() == true) {
        this.$store.commit("Toast_State", this.toast);
        this.loading = true;
        this.$admin.put('edit-bottle-type/' + this.edit_item.id, this.edit_item)
            .then(res => {
              if (res.status == 200) {
                this.loading = false;
                this.CloseModalEdit();
                this.edit_item = {};
                this.getData();
                this.reset();
                this.$store.commit("Toast_State", this.toast);
              }
            }).catch(error => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
          if (error.response.status == 422) {
            let obj = error.response.data.errors;
            for (let [key, truck_user] of Object.entries(obj)) {
              this.server_errors[key] = truck_user[0];
            }
          }
        });
      }
    },
    CloseModalEdit() {
      this.edit_item = {};
      this.reset();
      this.$store.commit("modalEdit_State", false);
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },
    deleteItem(id) {
      this.item_id = id;
      this.$store.commit("modalDelete_State", true);
    },
    deleteItemConfirm() {
      this.loading = true;
      this.$admin.delete('delete-bottle-type/' + this.item_id).then(res => {
        if (res.status == 200) {
          this.$store.commit("modalDelete_State", false);
          this.loading = false;
          this.getData();
          this.$store.commit("Toast_State", this.toast);
        }
      }).catch((error) => {
        let message = "Something went wrong";
        if (error.response.status == 422) {
          let obj = error.response.data.errors;
          for (let [key, user] of Object.entries(obj)) {
            this.server_errors[key] = user[0];
            message = this.server_errors.id;
          }
        }
        this.$store.commit("Toast_State", {value: true, color: 'error', msg: message});
        this.$store.commit("modalDelete_State", false);
        this.loading = false;
      })
    },
    reset() {
      this.$refs.form.reset();
    },
  },
  watch: {},
  created() {
    this.getData();
  },
}

</script>

<style>
.primary-color {
  color: #00c1d2;
}

@import "../../../public/scss/main.scss";
</style>